import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import Swal from "sweetalert2";
import "./style.css";
import Header from "../Dashboard/Header";
import { uploadFile, updatePrescription } from "../../api/Api";

export const CapturePrescription = ({
  choosePrescription,
  isUpload = false,
}) => {
  const webcamRef = useRef(null);
  const fileInputRef = useRef(null); // Ref for file input
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [isUploadE, setIsUpload] = useState(false);
  const [facingMode, setFacingMode] = useState("environment"); // Default to front camera

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there's state passed from the Link
    if (isUpload) {
      setIsUpload(true);
      handleUploadButtonClick();
    }
  }, [isUpload]);

  const handleUploadComplete = () => {
    navigate("/upload-prescription");
  };

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    triggerNameAlert(imageSrc);
  };

  const triggerNameAlert = (imageSrc) => {
    Swal.fire({
      title: "",
      input: "text",
      inputLabel: "Prescription Name",
      inputPlaceholder: "Enter a name",
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const loadingSwal = Swal.fire({
          title: "Processing...",
          text: "Please wait while we upload your image.",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const prescriptionName = result.value;

        // Convert base64 image to Blob
        const blob = await fetch(imageSrc).then((res) => res.blob());

        // Call uploadFile function
        const fileName = await uploadFile(blob, "prescription");
        if (fileName) {
          // Call the updatePrescription API
          const result = await updatePrescription(
            fileName,
            prescriptionName,
            new Date().toISOString().split("T")[0]
          );
          if (choosePrescription) {
            choosePrescription(result.prescription_id);
          } else {
            if (result) {
              Swal.fire({
                title: "Prescription uploaded successfully",
                text: "Do you want to place an order with this prescription?",
                icon: "success",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
              }).then((r) => {
                if (r.isConfirmed) {
                  navigate("/place-order", {
                    state: {
                      isUpload: true,
                      prescription_id: result.prescription_id,
                    },
                  });
                }
              });
            }
          }
        }
      }
    });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(URL.createObjectURL(selectedFile));
      triggerNameAlertForFile(selectedFile);
    }
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const triggerNameAlertForFile = (file) => {
    Swal.fire({
      title: "",
      input: "text",
      inputLabel: "Prescription Name",
      inputPlaceholder: "Enter the name",
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const loadingSwal = Swal.fire({
          title: "Processing...",
          text: "Please wait while we upload your image.",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const prescriptionName = result.value;

        // Call uploadFile function
        const fileName = await uploadFile(file, "prescription");
        if (fileName) {
          // Call the updatePrescription API
          const result = await updatePrescription(
            fileName,
            prescriptionName,
            new Date().toISOString().split("T")[0]
          );
          if (result) {
            Swal.fire({
              title: "Prescription uploaded successfully",
              text: "Do you want to place an order with this prescription?",
              icon: "success",
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((r) => {
              if (r.isConfirmed) {
                navigate("/place-order", {
                  state: {
                    isUpload: true,
                    prescription_id: result.prescription_id,
                  },
                });
              }
            });

          }
        }
      }
    });
  };

  const handleback = () => {
    navigate("/dashboard", { replace: true });
  };

  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  return (
    <>
      <Header
        title={
          choosePrescription ? "Choose Prescription" : "Capture Prescription"
        }
      />
      <div className="capture-prescription">
        <div className="cam">
          {!isUploadE && (
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                height="100%"
                videoConstraints={{ facingMode }}
              />
          )}
        </div>
        <div className="controls">
          <div className="line-system-upload-wrapper">
            <img
              className="img"
              alt="Line system upload"
              src="/switch-camera.png"
              onClick={switchCamera}
            />
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
          {!isUploadE && (
            <div className="capture-button" onClick={captureImage}>
              <div className="ellipse" />
              <div className="ellipse-2" />
            </div>
          )}
          <div className="line-system-close-wrapper" onClick={handleback}>
            <img
              className="img"
              alt="Line system close"
              src="/line-system-close-circle.svg"
            />
          </div>
        </div>
      </div>
    </>
  );
};
