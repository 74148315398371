import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import "./style.css";
import {
  getUser,
  getCategories,
  getDefaultAddress,
  getCartCount,
  getBanner,
} from "../../api/Api"; // Assuming this function exists
import Navigation from "./Navigation";
import { Link, useNavigate } from "react-router-dom";
import HeadActions from "../../components/HeadActions/HeadActions";
import { useCart } from "../../api/stateContext";
import { useSwipeable } from "react-swipeable";
import { Footer } from "../LandingPage/LandingPage";
import { DashboardHeader } from "./DashboardHeader";

export const Dashboard = () => {
  const [slides, setSlides] = useState([
    {
      banner: "banner1.jpg",
      header: "Refill your Medicines on time",
      subHeader: "Our experts will contact you for your refills on medicines",
      image: "image-12.png",
    },
    {
      banner: "banner2.jpg",
      header: "Stay Healthy and Safe",
      subHeader: "We provide timely updates on your health needs",
      image: "image-12.png",
    },
    {
      header: "Medication Reminders",
      subHeader: "Never miss a dose with our automated reminders",
      image: "image-12.png",
    },
  ]);

  const [user, setUser] = useState({
    name: "",
    location: "",
  });

  const [videoSlides] = useState([
    {
      videourl: "https://www.youtube.com/embed/BjZt63Ce7Zw",
    },
    {
      videourl: "https://www.youtube.com/embed/BjZt63Ce7Zw",
    },
    {
      videourl: "https://www.youtube.com/embed/BjZt63Ce7Zw",
    },
    {
      videourl: "https://www.youtube.com/embed/BjZt63Ce7Zw",
    },
    {
      videourl: "https://www.youtube.com/embed/BjZt63Ce7Zw",
    },
    {
      videourl: "https://www.youtube.com/embed/BjZt63Ce7Zw",
    },
  ]);

  const [categories, setCategories] = useState([]); // State to manage categories
  const { dispatch } = useCart();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [transformX, setTransformX] = useState(0);

  const navigate = useNavigate();

  const handleScrollToSection = (section) => {
    window.location.href = "/landing#" + section;
  };

  const handleDotClick = (index) => {
    let leftwidth = 0;
    for (let i = 0; i < index; i++) {
      leftwidth =
        leftwidth +
        parseFloat(
          window
            .getComputedStyle(document.querySelector(".slider-" + i))
            .width.replace("px", "")
        );
    }
    const distance = -leftwidth;
    setTransformX(distance);
    setCurrentIndex(index);
  };

  const [currentVIndex, setCurrentVIndex] = useState(0);
  const [transformVX, setVTransformX] = useState(0);

  const handleDotVClick = (index) => {
    const distance = index * -220;
    setVTransformX(distance);
    setCurrentVIndex(index);
  };

  const handleVideoClick = (videoUrl) => {
    Swal.fire({
      html: `<iframe width="100%" height="315px" src="${videoUrl}?autoplay=1" frameborder="0"></iframe>`,
      width: "100%",
      padding: "0em",
      background: "#fff",
      showConfirmButton: false,
      confirmButtonColor: "#3085d6",

      onClose: () => {
        Swal.close();
      },
    });
  };

  useEffect(() => {
    const fetchCategories = async () => {
      Swal.fire({
        title: "Loading...",
        text: "Fetching categories, please wait",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const categoryData = await getCategories(); // Fetch categories from API
        setCategories(categoryData); // Update categories state
        const bannerData = await getBanner("home_banner");
        setSlides([...bannerData]);

        // setSlides(bannerData);
        Swal.close(); // Close the loader
      } catch (error) {
        console.error("Error fetching categories", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to load categories!",
        });
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex = (currentIndex + 1) % slides.length;
      handleDotClick(nextIndex);

      const nextVIndex = (currentVIndex + 1) % videoSlides.length;
      handleDotVClick(nextVIndex);
    }, 5000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [currentIndex, slides.length, currentVIndex, videoSlides.length]);

  const handleCategories = (category) => {
    navigate("/searchbox", { state: { category: category } });
  };
  useEffect(() => {
    const fetchCartCount = async () => {
      try {
        const userData = getUser();
        if (userData.isLoggedIn) {
          const cartCount = await getCartCount(userData.id); // Fetch cart count
          let cartItems = cartCount.cart_items.split(";");
          // remove empty strings
          cartItems = cartItems.filter((item) => item !== "");
          const cartItemCount = cartItems.length;
          dispatch({ type: "UPDATE_COUNT", payload: cartItemCount });
        }
      } catch (error) {
        console.error("Failed to fetch cart count", error);
      }
    };

    fetchCartCount();
  }, []); // Add dependencies if needed

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      const nextIndex = (currentIndex + 1) % slides.length;
      handleDotClick(nextIndex);
    },
    onSwipedRight: () => {
      const prevIndex = (currentIndex - 1 + slides.length) % slides.length;
      handleDotClick(prevIndex);
    },
  });

  const swipeVHandlers = useSwipeable({
    onSwipedLeft: () => {
      const nextVIndex = (currentVIndex + 1) % videoSlides.length;
      handleDotVClick(nextVIndex);
    },
    onSwipedRight: () => {
      const prevVIndex =
        (currentVIndex - 1 + videoSlides.length) % videoSlides.length;
      handleDotVClick(prevVIndex);
    },
  });

  const handleBannerClick = (tnc) => {
    Swal.fire({
      title: "Terms and Conditions",
      html: tnc,
      focusConfirm: false,
      confirmButtonText: "Close",
      icon: "info",
    });
  };

  return (
    <>
      <div className="dashboard">
        <DashboardHeader />

        <div className="web-head-margin"/>

        <div className="dashboard-container">
          <div className="dashboard-item">
            <div className="slider-section">
              <div className="slider-flex"                 {...swipeHandlers}
              >
              {Array(4).fill().map((_, index) => (
              <div
                key={index}
                className="slider"
                style={{
                  transform: `translateX(${transformX}px)`,
                  transition: "transform 0.3s ease",
                }}
              >
                {slides.map((slide, index) => (
                  <div
                    key={index}
                    className={`overlap-group-wrapper slider-${index} ${
                      index === currentIndex ? "active" : ""
                    }`}
                    onClick={() => handleBannerClick(slide.tnc)} // Add onClick handler to open SweetAlert
                  >
                    {slide.banner ? (
                      <img
                        className="banner"
                        alt="Banner"
                        src={
                          "https://medingen.s3.us-east-1.amazonaws.com/banner/" +
                          slide.banner
                        }
                      />
                    ) : (
                      <div className="overlap-slider">
                        <img
                          className="image"
                          alt="Slide Image"
                          src={slide.image}
                        />
                        <div className="frame-10">
                          <p className="text-wrapper-8">{slide.header}</p>
                          <p className="text-wrapper-9">{slide.subHeader}</p>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              ))}
              </div>
              
            <div className="frame-11">
                {slides.map((_, index) => (
                  <div
                    key={index}
                    className={`rectangle-${
                      index === currentIndex ? "23" : "24"
                    }`}
                    onClick={() => handleDotClick(index)}
                  />
                ))}
              </div>
            </div>
            
          </div>

          <div className="dashboard-item">
            <div className="order-with-presc">
              <div className="group-wrapper">
                <div className="frame-wrapper">
                  <div className="frame-6">
                    <div className="frame-7">
                      <div className="text-wrapper-5">
                        Order with Prescription
                      </div>
                      <p className="text-wrapper-6">
                        You can take a photo of your prescription or select from
                        the gallery
                      </p>
                    </div>
                    <Link to="/upload-prescription" className="frame-8">
                      <img className="vector" alt="Vector" src="/vector.svg" />
                      <div className="text-wrapper-7">Upload Now</div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="group-wrapper-2">
                <div className="frame-wrapper">
                  <div className="frame-6">
                    <div className="frame-7">
                      <div className="text-wrapper-5">Chat with us</div>
                      <p className="text-wrapper-6">
                        You can chat with our experts for any queries
                      </p>
                    </div>
                    <div
                      className="frame-8"
                      onClick={() => {
                        window.open("https://wa.me/+916381975763", "_blank");
                      }}
                    >
                      <img className="vector" alt="Vector" src="/vector.svg" />
                      <div className="text-wrapper-7">Chat Now</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="dashboard-item">
            <div className="frame-12">
              <Link to={"/rewards"} className="frame-13">
                <div className="image-wrapper">
                  <img className="image-4" alt="Image" src="/image-20.png" />
                </div>
                <div className="text-wrapper-11">View MIG Coins</div>
              </Link>
              <Link to={"/orders"} className="frame-13">
                <div className="group-17">
                  <img className="image-4" alt="Image" src="/image-21.png" />
                </div>
                <div className="text-wrapper-11">Refill Medicines</div>
              </Link>
              <Link to={"/searchbox"} className="frame-13">
                <div className="group-18">
                  <img className="image-4" alt="Image" src="/image-22.png" />
                </div>
                <div className="text-wrapper-11">Compare Medicine</div>
              </Link>
              <Link to={"/select-prescription"} className="frame-13">
                <div className="group-19">
                  <img className="image-4" alt="Image" src="/image-22-1.png" />
                </div>
                <div className="text-wrapper-11">
                  Your <br />
                  Prescriptions
                </div>
              </Link>
            </div>
          </div>

          <div className="dashboard-item">
            <p className="text-wrapper-head">
              Look what our customers say about us
            </p>
          </div>
          <div className="dashboard-item">
            <div className="slider-section">
              <div
                className="slider"
                style={{
                  transform: `translateX(${transformVX}px)`,
                  transition: "transform 0.3s ease",
                }}
                {...swipeVHandlers}
              >
                {videoSlides.map((slide, index) => (
                  <div
                    key={index}
                    className={`overlap-group-wrapper ${
                      index === currentVIndex ? "active" : ""
                    }`}
                    onClick={() => handleVideoClick(slide.videourl)} // Add onClick handler to open SweetAlert
                  >
                    <div className="overlap-5">
                      <iframe
                        width="100%"
                        height="200px"
                        src={
                          slide.videourl +
                          "?controls=0&modestbranding=1&rel=0&autoplay=0"
                        }
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                ))}
              </div>
              <div className="frame-11">
                {videoSlides.map((_, index) => (
                  <div
                    key={index}
                    className={`rectangle-${
                      index === currentVIndex ? "23" : "24"
                    }`}
                    onClick={() => handleDotVClick(index)}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="dashboard-item">
            <p className="text-wrapper-head">Shop By Category</p>
          </div>
          <div className="dashboard-item">
            <div className="frame-1222">
              {categories.map((category, index) => (
                <div
                  key={index}
                  className="frame-131"
                  onClick={() => {
                    handleCategories(category.category_name);
                  }}
                >
                  <div className="group-17">
                    <img
                      className="image-2"
                      alt="Image"
                      src={
                        "https://medingen.s3.us-east-1.amazonaws.com/categories/" +
                        category.category_image_url
                      }
                    />
                  </div>
                  <div className="text-wrapper-10">{category.display_name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="landing-page">
          <Footer handleScrollToSection={handleScrollToSection} />
        </div>
      </div>
      <Navigation />
    </>
  );
};
