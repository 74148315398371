import React, { useState, useEffect } from "react";
import HeadActions from "../../components/HeadActions/HeadActions";
import { Link, useNavigate } from "react-router-dom";
import { getDefaultAddress, getUser } from "../../api/Api";
import { Footer } from "../LandingPage/LandingPage";

export const DashboardHeader = ({ userData }) => {
  const [user, setUser] = useState({
    name: "",
    location: "",
  });
  const handleScrollToSection = (section) => {
    window.location.href = "/landing#" + section;
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = getUser(); // Assuming getUser fetches user details from storage or an API

        console.log("User data", data);

        if (data.isLoggedIn) {
          const userData = { ...data };

          // If selectedAddress exists, fetch the default address
          if (userData.selectedAddress) {
            try {
              const response = await getDefaultAddress();

              if (response && response.default_address) {
                const { address1, state } = response.default_address;
                setUser({
                  name: userData.firstName || userData.name,
                  location: `${address1}, ${state}`, // Set location as address1 + state
                });
                return; // Exit early if the default address is found and set
              } else {
                setUser({
                  name: userData.firstName || userData.name,
                  location: "", // If default address is empty, set location to an empty string
                });
              }
            } catch (error) {
              console.error("Error fetching default address", error);
              setUser({
                name: userData.firstName || userData.name,
                location: "", // Handle error case by setting location to an empty string
              });
            }
          } else {
            setUser({
              name: userData.firstName || userData.name,
              location: userData.defaultAddress || "",
            });
          }
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }

      // Geolocation logic only runs if user.location is still empty
      if (!user.location) {
        // No user logged in, request location
        if (navigator.geolocation) {
          console.log("Geolocation is supported by this browser.");
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
              console.log("Latitude is :", latitude);
              // Reverse geocode to get the location name
              const locationResponse = await fetch(
                `https://geocode.maps.co/reverse?lat=${latitude}&lon=${longitude}&api_key=66d4782bb3d09960167594uts506c6d`
              );
              const locationData = await locationResponse.json();
              const city = locationData.address.city || "Unknown City";
              const area = locationData.address.road || "Unknown Area";
              setUser((prevState) => ({
                ...prevState,
                location: `${area}, ${city}`,
              }));
            },
            (error) => {
              console.error("Error obtaining location", error);
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
        }
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      <div className="dashboard-container dash-header">
        <div className="dashboard-item">
          <div className="overlap-header">
            <div className="frame-header"></div>

            <HeadActions />
          <Link to={"/dashboard"}>
            <img
              className="finalmiglogo"
              alt="Finalmiglogo"
              src="/migfulllogo.png"
            />
            <div className="img-name">Medingen</div>
            </Link>
            <div className="address-actions-group">
              <div className="address-actions">
                <Link
                  to="/savedaddress"
                  className="rectangle-8-saved-address clickable"
                />
                <div className="frame-3-address clickable">
                  <img className="location" alt="Location" src="/location.svg" />
                  <div className="text-wrapper-dash-header">
                    {user.location}
                  </div>
                </div>
                <Link
                  to="/savedaddress"
                  className="text-wrapper-2-header clickable"
                >
                  Change
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="dashboard-item dash-name">
          <div className="hey-skanda">
            <span className="span-header">
              {user.name !== "" ? "Hey " : "Welcome"}
            </span>
            <span className="text-wrapper-4-header">
              {user.name.substring(0, 12)}!
            </span>
          </div>
        </div>

        <div className="dashboard-item">
          <Link to="/searchbox" className="overlap-wrapper-header">
            <div className="overlap-4-header">
              <img className="search-header" alt="Search" src="/search.svg" />
              <p className="p-header">What are you looking for..</p>
              <img
                className="fluent-mic-head"
                alt="Fluent mic"
                src="/fluent-mic-20-regular.svg"
              />
            </div>
          </Link>
        </div>

      </div>
    </>
  );
};
