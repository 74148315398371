import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import "./style.css";
import Navigation from "../Dashboard/Navigation";
import Header from "../Dashboard/Header";
import {
  getUser,
  getProfileData,
  handleSignOut,
  updateProfileData,
  uploadFile,
  sendOTP,
} from "../../api/Api"; // Import updateProfileData
import { Link, useNavigate } from "react-router-dom"; // Assuming you're using react-router-dom
import Cookies from "js-cookie";

export const ProfileView = () => {
  const [profile, setProfile] = useState(null); // Initialize as null
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is logged in
    const user = getUser();

    if (!user.isLoggedIn) {
      navigate("/login"); // Redirect to login pagetr
      return;
    } else {
      if (!Cookies.get("customer_name")) {
        navigate("/create-profile");
      }
      // Show SweetAlert loading progress bar
      Swal.fire({
        title: "Loading...",
        text: "Fetching profile data, please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Fetch profile data (mocked API call)
      getProfileData()
        .then((data) => {
          setProfile(data);
          Swal.close(); // Close the SweetAlert progress bar
        })
        .catch((error) => {
          Swal.fire("Error", "Failed to load profile data", "error");
          console.error(error);
        });
    }
  }, [navigate]);

  const changepass = () => {
    sendOTP(profile.phone, navigate);
    navigate("/login2", { state: { phoneNumber: profile.phone } });
  };

  // Helper functions for validation
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateDOB = (dob) => {
    // Check if the DOB matches the format DD-MM-YYYY
    const dobRegex = /^\d{2}-\d{2}-\d{4}$/;
    if (!dobRegex.test(dob)) {
      return false;
    }

    // Split the date into day, month, and year
    const [day, month, year] = dob.split("-").map(Number);
    const date = new Date(`${year}-${month}-${day}`);

    // Check if the date is valid and within reasonable bounds
    const isValidDate =
      date.getFullYear() === year &&
      date.getMonth() + 1 === month &&
      date.getDate() === day;

    if (!isValidDate) {
      return false;
    }

    // Check if the date is in the past and within a reasonable age range
    const today = new Date();
    const age = today.getFullYear() - year;

    // Set reasonable age limits (e.g., age should be between 0 and 120)
    if (date > today || age > 120 || age < 0) {
      return false;
    }

    return true;
  };

  const validateCustomerName = (name) => {
    const nameRegex = /^[a-zA-Z\s]+$/; // Alphanumeric characters and spaces
    return nameRegex.test(name);
  };
  const handleProfileUpdate = (field, currentValue, text) => {
    Swal.fire({
      title: `Update Details`,
      text: text,
      input: "text",
      inputValue: currentValue,
      showCancelButton: true,
      confirmButtonText: "Update",
      cancelButtonText: "Close",
      showLoaderOnConfirm: true,
      preConfirm: (newValue) => {
        if (newValue !== currentValue) {
          // Validate email format
          if (field === "email" && !validateEmail(newValue)) {
            Swal.showValidationMessage("Invalid email format");
            return false;
          }
          // Validate date of birth format
          if (field === "dob" && !validateDOB(newValue)) {
            Swal.showValidationMessage("Invalid date of birth format (DD-MM-YYYY)");
            return false;
          }
          // Validate customer name format
          if (field === "customer_name" && !validateCustomerName(newValue)) {
            Swal.showValidationMessage("Customer name can only contain alphabetic characters and spaces");
            return false;
          }
          // Proceed with API call if validation passes
          return updateProfileData({ [field]: newValue })
            .then(() => {
              if (field === "customer_name") {
                Cookies.set("customer_name", newValue);
              }
              return getProfileData();
            })
            .then((updatedProfile) => {
              setProfile(updatedProfile);
            })
            .catch((error) => {
              Swal.fire("Error", "Failed to update profile data", "error");
              console.error(error);
            });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      didOpen: () => {
        const input = Swal.getInput();
        if (field === "dob" && input) {
          // Apply custom styles
          input.classList.add("custom-dob-input");
  
          // Auto-format input as DD-MM-YYYY with dashes, and handle backspace
          input.addEventListener("input", (event) => {
            let value = event.target.value.replace(/\D/g, ""); // Remove all non-digit characters
  
            // Handle backspace by checking if length is less than expected
            if (event.inputType === "deleteContentBackward") {
              if (value.length === 2 || value.length === 4) {
                value = value.slice(0, -1); // Remove the last character if it's a dash position
              }
            }
  
            // Add dashes after 2 and 4 characters for the format DD-MM-YYYY
            if (value.length >= 2) value = `${value.slice(0, 2)}-${value.slice(2)}`;
            if (value.length >= 5) value = `${value.slice(0, 5)}-${value.slice(5, 9)}`;
            if (value.length > 10) value = value.slice(0, 10); // Limit to YYYY format
  
            event.target.value = value;
          });
        }
      },
    });
  };
    
      
  // Handle logout
  const handleLogout = () => {
    handleSignOut(); // Call the sign out function
    navigate("/login"); // Redirect to login page after logout
  };

  // Handle profile picture change
  const handleProfilePictureChange = () => {
    Swal.fire({
      title: '<h4 style="font-size: 20px;">Update your profile picture</h4>',
      html: `
      <div style="display: flex; flex-direction: column; align-items: center;">
        <label for="file-upload" class="custom-file-upload" style="cursor: pointer; margin-bottom: 10px; width: 85%">
          <img src="/choosefile.svg" alt="Upload Icon" style="width: 100%"/>
        </label>
        <input id="file-upload" type="file" accept="image/*" style="display: none;"/>
      <div style="display: flex; align-items: center; margin: 20px 0; width: 100%;">
        <hr style="flex-grow: 1; height: 1px; background-color: #ccc; border: none;"/>
        <span style="margin: 0 10px; font-size: 16px; font-weight: bold;">OR</span>
        <hr style="flex-grow: 1; height: 1px; background-color: #ccc; border: none;"/>
      </div> <!-- OR Line with CSS -->
      <img src="/takephoto.svg" alt="Camera Icon" id="take-photo" style="width: 50%; cursor: pointer;"/>
      </div>
    `,
      showCancelButton: true,
      cancelButtonText: "Cancel",
      focusConfirm: false,
      didOpen: () => {
        // Handle file upload
        const fileInput = document.getElementById("file-upload");
        fileInput.addEventListener("change", () => {
          const file = fileInput.files[0];
          if (file) {
            Swal.showLoading(); // Show loading while uploading
            const reader = new FileReader();

            reader.onload = (e) => {
              setProfile((prevProfile) => ({
                ...prevProfile,
                profilePicture: e.target.result, // Set the image data URL
              }));
            };

            reader.readAsDataURL(file); // Convert file to data URL

            // You can call the upload API here if necessary
            const filename = uploadFile(file, "profilepic"); // Call your API
          } else {
            Swal.showValidationMessage("No file selected");
          }
        });

        // Handle take photo action
        const takePhotoButton = document.getElementById("take-photo");
        takePhotoButton.addEventListener("click", () => {
          // Open the camera app
          const constraints = { video: true };
          navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
              const video = document.createElement("video");
              video.style.width = "100%";
              video.srcObject = stream;
              video.play();

              // create a div element with 100% width and then add the video element to it
              const vdiv = document.createElement("div");
              vdiv.style.width = "100%";
              vdiv.style.display = "flex";
              vdiv.style.justifyContent = "center";
              vdiv.appendChild(video);

              Swal.fire({
                title: "Take a photo",
                html: vdiv,
                confirmButtonText: "Capture",
                showCancelButton: true,
                cancelButtonText: "Cancel",
                preConfirm: async () => {
                  Swal.showLoading();

                  // Capture the photo from the video stream
                  const canvas = document.createElement("canvas");
                  canvas.width = video.videoWidth;
                  canvas.height = video.videoHeight;
                  canvas.getContext("2d").drawImage(video, 0, 0);
                  const imageData = canvas.toDataURL("image/png");
                  video.srcObject.getTracks().forEach((track) => track.stop()); // Stop the camera

                  // Convert base64 to Blob
                  function dataURLToBlob(dataUrl) {
                    const arr = dataUrl.split(",");
                    const mime = arr[0].match(/:(.*?);/)[1];
                    const bstr = atob(arr[1]);
                    let n = bstr.length;
                    const u8arr = new Uint8Array(n);
                    while (n--) {
                      u8arr[n] = bstr.charCodeAt(n);
                    }
                    return new Blob([u8arr], { type: mime });
                  }

                  // Convert Blob to File object
                  const blob = dataURLToBlob(imageData);
                  const file = new File([blob], "profilepic.png", {
                    type: "image/png",
                  });

                  setProfile((prevProfile) => ({
                    ...prevProfile,
                    profilePicture: imageData, // Set the captured image data URL
                  }));

                  // You can call the upload API here
                  const filename = await uploadFile(file, "profilepic");
                },
                willClose: () => {
                  video.srcObject.getTracks().forEach((track) => track.stop()); // Ensure camera stops if modal is closed
                },
              });
            })
            .catch((err) => {
              Swal.fire("Error", "Unable to access the camera.", "error");
            });
        });
      },
    });
  };

  if (!profile) {
    return null; // Render nothing or a loader if profile data is not yet available
  }

  return (
    <>
      <Header />

      <div className={`profile-view ${profile.theme}`}>
        <div className="profile-info">
          <img
            className="profile-picture"
            alt="Profile"
            src={profile.profilePicture}
            onClick={handleProfilePictureChange} // Add onClick handler
          />
          <div className="profile-details">
            <div className="profile-name">{profile.name}</div>
            <div
              className="edit-profile-link"
              onClick={() =>
                handleProfileUpdate(
                  "customer_name",
                  profile.name,
                  "Update your name"
                )
              }
            >
              Edit
            </div>
          </div>
          <div className="logout-section">
            <div className="logout-button" onClick={handleLogout}>
              <div className="logout-text">Log-out</div>
              <img
                className="logout-icon"
                alt="Logout Icon"
                src="/logout-1.svg"
              />
            </div>
          </div>
        </div>

        <div className="coins-section">
          <div className="coins-title">Your MIG Coins</div>
          <div className="coins-value">{profile.coins}</div>
        </div>

        <div className="details-section">
          <div className="details-item">
            <div className="details-label">Mail ID</div>
            <div className="details-value">
              <div className="details-value-text">{profile.email}</div>
            </div>
            <div
              className="change-link"
              onClick={() =>
                handleProfileUpdate("email", profile.email, "Update your email")
              }
            >
              Edit
            </div>
          </div>
          <div className="details-item">
            <div className="details-label">Date of Birth</div>
            <div className="details-value">{profile.dob}</div>
            <div
              className="change-link"
              onClick={() =>
                handleProfileUpdate(
                  "dob",
                  profile.dob,
                  "Update your date of birth (dd-mm-yyyy) "
                )
              }
            >
              Edit
            </div>
          </div>
          <div className="details-item">
            <div className="details-label">Phone Number</div>
            <div className="details-value">{profile.phone}</div>
          </div>
          <Link to="/savedaddress" className="details-item">
            <div className="details-label">Change Address</div>
            <img
              className="back-arrow"
              alt="Change Address Arrow"
              src="/line-arrow-chevron-left-1.svg"
            />
          </Link>
          <div className="details-item" onClick={changepass}>
            <div className="details-label">Change Password</div>
            <img
              className="back-arrow"
              alt="Change Password Arrow"
              src="/line-arrow-chevron-left-1.svg"
            />
          </div>
          <Link to="/orders" className="details-item">
            <div className="details-label">Your orders</div>
            <img
              className="back-arrow"
              alt="Your Orders Arrow"
              src="/line-arrow-chevron-left-1.svg"
            />
          </Link>
          <Link to="/upload-prescription" className="details-item">
            <div className="details-label">Prescriptions</div>
            <img
              className="back-arrow"
              alt="Prescriptions"
              src="/line-arrow-chevron-left-1.svg"
            />
          </Link>
          {/* <div className="support-contact">
            <div className="details-label">Contact our support</div>
            <div className="support-info">
              <div className="details-label">Toll free number (pilot) </div>
              <div className="details-label">+91 6381975763</div>
            </div>
          </div> */}
        </div>
        <div className="margin-72"></div>
      </div>
      <Navigation />
    </>
  );
};
