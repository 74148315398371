import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { checkCustomer, getUser, sendOTP } from "../../api/Api";
import "./style.css";
import { useCart } from "../../api/stateContext";
import Splash3 from "../Splash3/Splash3";

const API_ENDPOINT = "your_api_endpoint"; // Replace with your actual API endpoint

export const Login1 = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  const { dispatch } = useCart();

  useEffect(() => {
    // Clear the cart count when the component mounts
    dispatch({ type: "UPDATE_COUNT", payload: 0 });
    const user = getUser();
    if (user.customer_id) {
      navigate("/dashboard");
    }
  }, [dispatch]);

  const handleSkip = () => {
    navigate("/dashboard");
  };

  const handleCreateAccountClick = () => {
    navigate("/create-profile");
  };

  const handleSocialMediaLogin = () => {
    Swal.fire({
      title: "Info",
      text: "Social media integration coming soon!",
      icon: "info",
      confirmButtonText: "OK",
    });
  };

  const handleContinue = () => {
    if (!phoneNumber) {
      Swal.fire({
        title: "Error!",
        text: "Please enter a phone number.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else {
      checkCustomer(phoneNumber, navigate);
    }
  };

  return (
    <div className="login1">
      <div className="login-wrapper">
        <div className="login-splash">
          <Splash3 embedded={true} />
        </div>
        <div className="login-box">
          <div className="login-container">
            <div className="skip-wrapper">
              <button className="skip-button" onClick={handleSkip}>
                Skip
              </button>
            </div>

            <div className="header">
              <div className="title">Enter your Mobile number</div>
              <p className="subtitle">We will send a confirmation code</p>
              <img className="ellipse" alt="Ellipse" src="/ellipse-159.svg" />
            </div>

            <div className="input-section">
              <div className="phone-label">Phone number</div>
              <div className="input-wrapper">
                <span>+91 - </span>
                <input
                  className="phone-input"
                  type="text"
                  placeholder="Enter Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>

            <div className="button-wrapper">
              <button className="continue-button" onClick={handleContinue}>
                Continue
                <img className="button-icon" alt="Arrow" src="/vector-3.svg" />
              </button>
            </div>

            <img className="separator" alt="Separator" src="/vector-179.svg" />

            <div className="continue-section">
              <div className="or-text">or continue with</div>
              <div className="social-icons">
                <div className="icon-wrapper" onClick={handleSocialMediaLogin}>
                  <img className="icon" alt="Google" src="/google.svg" />
                </div>
                <div className="icon-wrapper" onClick={handleSocialMediaLogin}>
                  <img className="icon" alt="Facebook" src="/mask-group.png" />
                </div>
                <div className="icon-wrapper" onClick={handleSocialMediaLogin}>
                  <img
                    className="icon"
                    alt="Instagram"
                    src="/frame-633086-insta.svg"
                  />
                </div>
              </div>
            </div>

            {/* <div className="create-account-section">
          <div className="create-account-text" onClick={handleCreateAccountClick}>Create New Account</div>
      </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
