import md5 from 'crypto-js/md5'; 
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'; // Import navigate hook
const API_ENDPOINT = 'https://medingen.in/api/';

// function to check if cookie exists
const checkLogin = () => {
    if (Cookies.get('jwt_token')) {
        return Cookies.get('jwt_token');
    }
    return false;
}

const getUser = () => {
    return {
        customer_id: Cookies.get('customer_id'),
        name: Cookies.get('customer_name'),
        email: Cookies.get('email'),
        location: Cookies.get('location'),
        selectedAddress: Cookies.get('selectedAddress'),
        isLoggedIn: Cookies.get('customer_id') ? true : false
    }
}

const handleSignIn = async (phone_number, otp, navigate) => {
    try {

      const response = await axios.post(API_ENDPOINT + "login_otp", { "phone_number": phone_number, "otp": otp.join("") });
  
      if (response.status === 200) {
        const token = response.data.token;
        Cookies.set('jwt_token', token);
        Cookies.set('customer_name', response.data.customer_name);
        Cookies.set('email', response.data.email);
        Cookies.set('customer_id', response.data.customer_id);
        navigate('/createpassword', { replace: true , state: { phoneNumber: phone_number, otp: otp.join("") } });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Invalid username or password. Please try again.',
          icon: 'error',
          confirmButtonText: 'Try again',
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        Swal.fire({
          title: 'Error!',
          text: 'Invalid OTP. Please try again.',
          icon: 'error',
          confirmButtonText: 'Try again',
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Error accessing the backend',
          icon: 'error',
          confirmButtonText: 'Try again later',
        });
      }
    }
  };

  export const handleSignInPassword = async (phone_number, password, navigate) => {
    try {
      password = md5(password).toString();

      const response = await axios.post(API_ENDPOINT + "login_password", { "phone_number": phone_number, "password": password });
  
      if (response.status === 200) {
        const token = response.data.token;
        const expiryDate = new Date();
        expiryDate.setMonth(expiryDate.getMonth() + 1); // Set expiry to 1 month from now
    
        Cookies.set('jwt_token', token, { expires: expiryDate });
        Cookies.set('customer_name', response.data.customer_name, { expires: expiryDate });
        Cookies.set('email', response.data.email, { expires: expiryDate });
        Cookies.set('customer_id', response.data.customer_id, { expires: expiryDate });
            
        navigate('/dashboard', {replace: true});

      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Invalid username or password. Please try again.',
          icon: 'error',
          confirmButtonText: 'Try again',
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        Swal.fire({
          title: 'Error!',
          text: 'Invalid password. Please try again.',
          icon: 'error',
          confirmButtonText: 'Try again',
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Error accessing the backend',
          icon: 'error',
          confirmButtonText: 'Try again later',
        });
      }
    }
  };

  export const getAveragePrice = async (composition) => {
    try {
      const response = await axios.post(`${API_ENDPOINT}avg_price`, {
          composition: composition
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching orders:', error);
      throw error;
    }
  };

  export const getOrders = async (page = 1, search) => {
    const token = Cookies.get('jwt_token');
    try {
      const response = await axios.get(`${API_ENDPOINT}orders?page=${page}&search=${search}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching orders:', error);
      throw error;
    }
  };
  


  export const updateProfileData = async (updatedData) => {
    try {
      const token = Cookies.get('jwt_token');
      const response = await axios.post(`${API_ENDPOINT}update_profile`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        Swal.fire('Success', 'Profile updated successfully', 'success');
        return response.data;
      } else {
        throw new Error('Failed to update profile');
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to update profile data', 'error');
      console.error(error);
      throw error;
    }
  };
  

  export const sendOTP = async (phoneNumber, navigate) => {
    try {
      const response = await axios.post(`${API_ENDPOINT}send_otp`, { "phone_number" : phoneNumber });

      if (response.status === 200) {
        navigate("/login2", { state: { phoneNumber } });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'User does not exist. Please try again.',
          icon: 'error',
          confirmButtonText: 'Try again',
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        Swal.fire({
          title: 'Error!',
          text: 'User does not exist. Please try again.',
          icon: 'error',
          confirmButtonText: 'Try again',
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Something went wrong. Please try again later.',
          icon: 'error',
          confirmButtonText: 'Try again later',
        });
      }
    }
  };

  export const createPassword = async (phone_number, password, otp, navigate) => {
    try {
      password = md5(password
        ).toString();
      const response = await axios.post(API_ENDPOINT + "create_password", { "phone_number": phone_number, "password": password, "otp": otp });
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Password created successfully!',
        }).then(() => {
          navigate("/dashboard", {replace: true });
        });
      
      
      } else {
        
        Swal.fire({
          title: 'Error!',
          text: 'Failed to create password',
          icon: 'error',
          confirmButtonText: 'Try again',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Something went wrong!',
        text: 'Failed to create password',
        icon: 'error',
        confirmButtonText: 'Try again',
      });
    }
  };


  export const createProfile = async (profileData, navigate) => {
    const token = Cookies.get('jwt_token');
    try {
      const response = await axios.post(API_ENDPOINT + "update_profile", profileData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        Cookies.set('customer_name', profileData.customer_name);
        Cookies.set('email', profileData.email);

        navigate("/profile",  { replace: true });
      } else {
        throw new Error("Failed to create profile");
      }
    } catch (error) {
      throw new Error("Failed to create profile: " + error.message);
    }
  };

  export const checkCustomer = async (phone_number, navigate) => {
    try {
      const response = await axios.post(API_ENDPOINT + "check_customer", { "phone_number": phone_number });
      if (response.status === 200) {
        if(response.data.exists){
          navigate("/enterpassword", { state: { phoneNumber: phone_number } });

        }else {
          sendOTP(phone_number, navigate);
        }
      } 
      else{
        Swal.fire({
          title: 'Error!',
          text: 'Something went wrong.',
          icon: 'error',
          confirmButtonText: 'Try again',
        });

      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Something went wrong.',
        icon: 'error',
        confirmButtonText: 'Try again',
      });
    }
  }



  
  const getCategories = async () => {
    const response = await axios.get(API_ENDPOINT + "home_categories", {
      
    });
    return response.data;
  }

  export const getRewardsTransactions = async (page = 1) => {
    const token = Cookies.get('jwt_token');

    try {
      const response = await axios.get(`${API_ENDPOINT}rewards?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }});
      return response.data;
    } catch (error) {
      console.error('Error fetching rewards:', error);
      throw error;
    }
  };
  
  export const getRewardsSummary = async () => {
    const token = Cookies.get('jwt_token');

    try {
      const response = await axios.get(`${API_ENDPOINT}rewards-summary`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }});
      return response.data;
    } catch (error) {
      console.error('Error fetching rewards summary:', error);
      throw error;
    }
  };
  

  export const getOffers = async (page = 1) => {
    try {
      const response = await axios.get(`${API_ENDPOINT}offers?page=${page}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching offers:', error);
      throw error;
    }
  };
  

  export const getNotifications = async (page = 1) => {
    const token = Cookies.get('jwt_token');
    try {
      const response = await axios.get(`${API_ENDPOINT}notifications?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching notifications:', error);
      throw error;
    }
  };
  
  export const markNotificationAsRead = async (notificationId) => {
    const token = Cookies.get('jwt_token');
    try {
      await axios.put(`${API_ENDPOINT}notifications/${notificationId}/mark-as-read`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error marking notification as read:', error);
      throw error;
    }
  };
  

  const getProductList = async (page = 1, query = '', text = '') => {
    const token = Cookies.get('jwt_token');
    try {
      const response = await axios.post(
        API_ENDPOINT + 'products',
        {
          page: page,
          query: query,
          text: text
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching product list:', error);
      throw error; 
    }
  };

  const handleSignOut = () => {
    Cookies.remove('jwt_token');
    Cookies.remove('customer_name');
    Cookies.remove('email');
    Cookies.remove('customer_id');
    Cookies.remove('location');
  }

  
export const getCartData = async () => {
  const token = Cookies.get('jwt_token');
  const response = await axios.get(`${API_ENDPOINT}cart`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};  

export const updateDeliveryAddress = async (addressId,cart_id) => {
  const token = Cookies.get('jwt_token');
  try {
    const response = await axios.post(`${API_ENDPOINT}update_delivery_address`, { address_id: addressId, cart_id: cart_id }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (response.status === 200) {
      return response.data;
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to update delivery address ' + response.statusText,
        icon: 'error',
        confirmButtonText: 'Okay',
      });
    }
  } catch (error) {
    console.error('Error updating delivery address:', error);
    Swal.fire({
      title: 'Error!',
      text: 'Failed to update delivery address',
      icon: 'error',
      confirmButtonText: 'Okay',
    });
  }
};

export const updateChoosePrescription = async (prescription_id) => {
  const token = Cookies.get('jwt_token');
  try {
    const response = await axios.post(`${API_ENDPOINT}update_choose_prescription`, { prescription_id: prescription_id }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (response.status === 200) {
      return response.data;
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to update prescription ' + response.statusText,
        icon: 'error',
        confirmButtonText: 'Okay',
      });
    }
  } catch (error) {
    console.error('Error updating prescription:', error);
    Swal.fire({
      title: 'Error!',
      text: 'Failed to update prescription',
      icon: 'error',
      confirmButtonText: 'Okay',
    });
  }
};

export const create_order = async (cart_id, total_amount, coupon_savings) => {
  const token = Cookies.get('jwt_token');
  try {
    const response = await axios.post(`${API_ENDPOINT}create_order`, { cart_id: cart_id, total_amount: total_amount, coupon_savings: coupon_savings  }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (response.status === 200) {
      return response.data;
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to create order ' + response.statusText,
        icon: 'error',
        confirmButtonText: 'Okay',
      });
    }
  } catch (error) {
    console.error('Error creating order:', error);
    Swal.fire({
      title: 'Error!',
      text: 'Failed to create order',
      icon: 'error',
      confirmButtonText: 'Okay',
    });
  }
};


export const placeOrder = async (cart_id, total_cart_value) => {
  const token = Cookies.get('jwt_token');
  try {
    const response = await axios.post(`${API_ENDPOINT}place_order`, {cart_id: cart_id, total_cart_value: total_cart_value}, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (response.status === 200) {
      return response.data;
    }
    else {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to place order ' + response.statusText,
        icon: 'error',
        confirmButtonText: 'Okay',
      });
    }
  } catch (error) {
     Swal.fire({
        title: 'Error!',
        text: 'Failed to place order, backend error ',
        icon: 'error',
        confirmButtonText: 'Okay',
      });
      
  }
};



export const updateCartData = async (updatedData, cart_id) => {
  const token = Cookies.get('jwt_token');
  try {
    const response = await axios.post(`${API_ENDPOINT}cart_update`, { quantities :updatedData, cart_id: cart_id}, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (response.status === 200) {
      return response.data;
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to update cart ' + response.statusText,
        icon: 'error',
        confirmButtonText: 'Okay',
      });

    }
  } catch (error) {
     Swal.fire({
        title: 'Error!',
        text: 'Failed to update cart, backend error ',
        icon: 'error',
        confirmButtonText: 'Okay',
      });
      
  }
};


  export const getProfileData = async () => {
    const token = Cookies.get('jwt_token');
    const response = await axios.get(API_ENDPOINT + "get_profile", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  
  function generateRandomFileName() {
    const randomString = Math.random().toString(36).substring(2, 8);
    return `file_${randomString}`;
}

export async function updatePrescription(imageUrl, prescriptionName, prescriptionDate) {
  try {
    const token = Cookies.get('jwt_token');

    const response = await axios.post(`${API_ENDPOINT}update_prescription`, {
      prescription_image_url: imageUrl,
      prescription_date: prescriptionDate,
      prescription_status: 'RECEIVED',
      prescription_comments: '',
      prescription_name: prescriptionName
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    if (response.status === 200) {
      console.log('Prescription updated successfully');
      return response.data;
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to update prescription ' + response.statusText,
        icon: 'error',
        confirmButtonText: 'Okay',
      });
    }
  } catch (error) {
    console.error('Error updating prescription:', error);
    Swal.fire({
      title: 'Error!',
      text: 'Error updating prescription. Try again later',
      icon: 'error',
      confirmButtonText: 'Okay',
    });
  }
}

export async function placePrescription(prescription_id, cart_id) {
  try {
    const token = Cookies.get('jwt_token');

    const response = await axios.post(`${API_ENDPOINT}place_prescription`, {
      prescription_id: prescription_id,
      cart_id: cart_id
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    if (response.status === 200) {
      console.log('Prescription order placed successfully');
      return response.data;
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to place order on prescription ' + response.statusText,
        icon: 'error',
        confirmButtonText: 'Okay',
      });
    }
  } catch (error) {
    console.error('Error updating prescription:', error);
    Swal.fire({
      title: 'Error!',
      text: 'Error placing prescription order. Try again later',
      icon: 'error',
      confirmButtonText: 'Okay',
    });
  }
}



  export async function uploadFile(file, prefix) {
    try {
      const token = Cookies.get('jwt_token');

        // Request pre-signed URL from Flask API
        const response = await axios.get(API_ENDPOINT +  'generate_presigned_url', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
    
            params: {
                file_name: file.name || generateRandomFileName(),
                content_type: file.type,
                prefix: prefix
            }
        });

        const { presigned_url, file_name } = response.data;

        // Use the pre-signed URL to upload the file to S3
        const uploadResponse = await axios.put(presigned_url, file, {
          headers: {
              'Content-Type': file.type,
          }
      });

        if (uploadResponse.status === 200) {
            console.log('File uploaded successfully with name:', file_name);
            if(prefix === 'profilepic'){
              updateProfileData({ profile_picture: file_name });
            }
            return file_name;

        } else {
            Swal.fire({
              title: 'Error!',
              text: 'File upload failed ' + uploadResponse.statusText,
              icon: 'error',
              confirmButtonText: 'Okay',
            });
        }
    } catch (error) {
      console.error('Error uploading file:', error);
      Swal.fire({
        title: 'Error!',
        text: 'Error uploading file. Try again later',
        icon: 'error',
        confirmButtonText: 'Okay',
      });
    }
}

const checkToken = (navigate) => {
  const token = Cookies.get('jwt_token');
  if (!token) {
    navigate('/login'); // Redirect to login if token is not found
    return null;
  }
  return token;
};



export const updateAddress = async (addressId, newAddress, navigate) => {
  try {
    const token = checkToken(navigate);
    if (!token) return;

    const response = await axios.post(API_ENDPOINT + 'update_address', {
      id: addressId,
      address: newAddress
    }, {
      headers: {
          Authorization: `Bearer ${token}`,
      }
    });

    if (response.status === 200) {
      Swal.fire({
        title: 'Success!',
        text: 'Address updated successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to update address. Please try again.',
        icon: 'error',
        confirmButtonText: 'Try again'
      });
    }
  } catch (error) {
    Swal.fire({
      title: 'Error!',
      text: error.response ? error.response.data.message : 'Error accessing the backend',
      icon: 'error',
      confirmButtonText: 'Try again later'
    });
  }
};

export const updateOrderAutoRefill = async (cart_id, auto_refill) => {
  try {
    const token = checkToken();
    if (!token) return;
    
    const response = await axios.post(API_ENDPOINT + 'update_order_auto_refill', {
      cart_id: cart_id,
      auto_refill: auto_refill
    }, {
      headers: {

        Authorization: `Bearer ${token}`,
      }
    });
    return response
  } catch (error) {
    Swal.fire({
      title: 'Error!',

      text: error.response ? error.response.data.message : 'Error accessing the backend',
      icon: 'error',
      confirmButtonText: 'Try again later'
    });
  }
};


export const addAddress = async (newAddress, navigate) => {
  try {
    const token = checkToken(navigate);
    if (!token) return;

    const response = await axios.post(API_ENDPOINT + 'add_address', {
      address: newAddress
    }, {
      headers: {
          Authorization: `Bearer ${token}`,
      }
    });

    if (response.status === 200) {
      Swal.fire({
        title: 'Success!',
        text: 'Address added successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to add address. Please try again.',
        icon: 'error',
        confirmButtonText: 'Try again'
      });
    }
  } catch (error) {
    Swal.fire({
      title: 'Error!',
      text: error.response ? error.response.data.message : 'Error accessing the backend',
      icon: 'error',
      confirmButtonText: 'Try again later'
    });
  }
};

export const deleteAddress = async (addressId, navigate) => {
  try {
    const token = checkToken(navigate);
    if (!token) return;

    const response = await axios.post(API_ENDPOINT + 'delete_address', {
      id: addressId
    }, {
      headers: {
          Authorization: `Bearer ${token}`,
      }
    });

    if (response.status === 200) {
      Swal.fire({
        title: 'Success!',
        text: 'Address deleted successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to delete address. Please try again.',
        icon: 'error',
        confirmButtonText: 'Try again'
      });
    }
  } catch (error) {
    Swal.fire({
      title: 'Error!',
      text: error.response ? error.response.data.message : 'Error accessing the backend',
      icon: 'error',
      confirmButtonText: 'Try again later'
    });
  }
};

export const listPrescriptions = async (navigate) => {
  try {
    const token = checkToken(navigate);
    if (!token) return [];

    const response = await axios.get(API_ENDPOINT + 'list_prescriptions', {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    if (response.status === 200) {
      return response.data.prescriptions;
    } else {
      return [];
    }
  } catch (error) {
    Swal.fire({
      title: 'Error!',
      text: error.response ? error.response.data.message : 'Error accessing the backend',
      icon: 'error',
      confirmButtonText: 'Try again later'
    });
    return [];
  }
};


export const listAddresses = async (navigate) => {
  try {
    const token = checkToken(navigate);
    if (!token) return;

    const response = await axios.get(API_ENDPOINT + 'list_addresses', {
      headers: {
          Authorization: `Bearer ${token}`,
      }
    });

    if (response.status === 200) {
      return response.data.addresses;
    } else {
      return [];
    }
  } catch (error) {
    Swal.fire({
      title: 'Error!',
      text: error.response ? error.response.data.message : 'Error accessing the backend',
      icon: 'error',
      confirmButtonText: 'Try again later'
    });
  }
};

export const selectAddress = async (addressId, navigate) => {
  try {
    const token = checkToken(navigate);
    if (!token) return;

    const response = await axios.post(API_ENDPOINT + 'select_address', {
      id: addressId
    }, {
      headers: {
          Authorization: `Bearer ${token}`,
      }
    });

    if (response.status === 200) {
      return
    } else {
      throw new Error('Failed to select address');
    }
  } catch (error) {
    throw new Error('Failed to select address: ' + error.message);
  }
};

export const getDefaultAddress = async (navigate) => {
  try {
    const token = checkToken(navigate);
    if (!token) return;

    const response = await axios.get(API_ENDPOINT + 'get_default_address', {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    if (response.status === 200) {
      const defaultAddress = response.data;
      return defaultAddress;
    } else {
    }
  } catch (error) {
    
  }
};

export const searchProducts = async (searchText, page, query={}) => {
  try {
    const response = await axios.post(`${API_ENDPOINT}products`, {
        text: searchText,
        page,
        query: query["query"]
    });
    return response.data; // Assuming API response directly contains the list of products
  } catch (error) {
    throw new Error("Error fetching products");
  }
};

export const getBanner = async (section) => {
  try {
    const response = await axios.post(`${API_ENDPOINT}banner`, {
      section: section
    });
    return response.data; 
  } catch (error) {
    throw new Error("Error fetching banner");
  }
};

export const requestProduct = async (productId, navigate) => {
  try {
    const token = checkToken(navigate);
    if (!token) return;

    const response = await axios.post(`${API_ENDPOINT}request-product`, {
      product_id: productId
    }, {      headers: {
      Authorization: `Bearer ${token}`,
    }
});
    return response.data;
  } catch (error) {
    throw new Error("Error requesting product");
  }
};

export const addToCart = async (productId, quantity, navigate) => {
  try {
    const token = checkToken(navigate);
    if (!token) return;

    const response = await axios.post(`${API_ENDPOINT}add-to-cart`, {
      product_id: productId,
      quantity
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    throw new Error("Error adding to cart");
  }
};

export const getProductDetails = async (id=0, name="") => {
  const response = await axios.get(API_ENDPOINT + `product_details/${id}?name=${name}`, {
    
  });
  return response.data;
}

export const loadCoupons = async (cart_id) => {
  const token = Cookies.get('jwt_token');
  try {
    const response = await axios.get(`${API_ENDPOINT}cart_coupons/${cart_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (response.status === 200) {
      return response.data;
    }
    else {
      throw new Error('Failed to load coupons');
    }
  } catch (error) {
    throw new Error('Failed to load coupons: ' + error.message);
  }
}

export const applyCouponAPI = async (couponCode, cart_id) => {
  const token = Cookies.get('jwt_token');
  try {
    Swal.showLoading()
    const response = await axios.post(`${API_ENDPOINT}apply_coupon`, { coupon_code: couponCode, cart_id: cart_id }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (response.status === 200) {
      Swal.fire({
        title: 'Success!',
        text: 'Coupon applied successfully.',
        icon: 'success',
        timer: 1500,
        confirmButtonText: 'OK'
      });
      return response.data;

    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Invalid coupon',
        icon: 'error',
        confirmButtonText: 'Okay',
      });

    }
  } catch (error) {
    Swal.fire({
      title: 'Error!',
      text: 'Invalid coupon',
      icon: 'error',
      confirmButtonText: 'Okay',
    });
  }
}


export const getCartCount = async (navigate) => {
  const token = checkToken(navigate);
  if (!token) return;

  const response = await axios.get(API_ENDPOINT + `cart_count`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
  return response.data;
}



export const getCouponDetails = async (code) => {
  const response = await axios.get(API_ENDPOINT + `coupon_details/${code}`, {
    
  });
  return response.data;
}


  export { checkLogin, handleSignIn, getCategories, getProductList, getUser, handleSignOut};
