import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './style.css';

const NAV_ITEMS = [
  {
    paths: ['/dashboard', '/searchbox', '/search-results', '/compareview', "/search-view-medicine", "/compare"],
    text: 'Home',
    activeIcon: '/home.svg',
    inactiveIcon: '/home_inactive.svg',
  },
  {
    paths: ['/offers', '/rewards'],
    text: 'Offers',
    activeIcon: '/offers_active.svg',
    inactiveIcon: '/offers.svg',
  },
  {
    paths: ['/notification'],
    text: 'Notification',
    activeIcon: '/noti.svg',
    inactiveIcon: '/noti-inactive.svg',
  },
  {
    paths: ['/profile'],
    text: 'Profile',
    activeIcon: '/profile-active.svg',
    inactiveIcon: '/profile.svg',
  },
];

const Navigation = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="main-navbar">
      {NAV_ITEMS.map(({ paths, text, activeIcon, inactiveIcon }) => (
        <Link
          key={paths.join(',')}
          to={paths[0]}
          className={`nav-item ${paths.includes(currentPath) ? 'active' : ''}`}
        >
            <img className="nav-icon" alt={text} src={paths.includes(currentPath) ? activeIcon : inactiveIcon} />
          <div className="nav-text">{text}</div>
        </Link>
      ))}
    </div>
  );
};

export default Navigation;
