import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Use useNavigate from react-router-dom
import "./style.css";
import HeadActions from "../../components/HeadActions/HeadActions";
import { DashboardHeader } from "./DashboardHeader";

const Header = ({ title }) => {
  const location = useLocation();
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const [headerTitle, setHeaderTitle] = useState("Profile");

  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Define titles based on route paths
    const routeTitles = {
      "/": "Home",
      "/profile": "Profile",
      "/notification": "Notifications",
      "/addressnew": "Address",
      "/savedaddress": "Saved Address",
      "/view-offer": "Offer Details",
      "/search-view-medicine": "View",
      "/compare": "Compare",
      "/offers": "Offers",
      "/upload-prescription": "Prescriptions",
      "/select-prescription": "Prescriptions",
      "/capture-prescription": "Prescriptions",
      "/order-progress": "Order Progress",
    };

    // Update the title based on the current path
    setHeaderTitle(
      title ? title : (routeTitles[location.pathname] || "")
    );
  }, [location.pathname]);

  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous route
  };

  return (
    <>
      {isMobile ? (
        <div className="header-bar">
          <div className="header-content">
            <img
              className="back-arrow"
              alt="Back Arrow"
              src="/line-arrow-chevron-left.svg"
              onClick={handleBackClick} // Add click event handler
            />
            <div className="header-title">{headerTitle}</div>
          </div>
          <HeadActions />
        </div>
      ) : (
        <>
          <div className="header-bar">
            <div className="header-content">
              <img
                className="back-arrow"
                alt="Back Arrow"
                src="/line-arrow-chevron-left.svg"
                onClick={handleBackClick} // Add click event handler
              />
              <div className="header-title">{headerTitle}</div>
            </div>
          </div>{" "}
          <DashboardHeader />
        </>
      )}
    </>
  );
};

export default Header;
