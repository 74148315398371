import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { placePrescription } from "../../api/Api"; // Import your API function

import "./style.css";
import Header from "../Dashboard/Header";

export const OrderPlacedSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchPrescription = async () => {
        const { prescription_id } = location.state;
        if (prescription_id) {
          Swal.fire({
            title: 'Processing...',
            text: 'Please wait while we process your prescription.',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });

          try {
            const response = await placePrescription(prescription_id);
            if (response) {
              // Response is successful
              Swal.close();
              setIsLoaded(true); // Set state to true to render the component
            } else {
              // No response, navigate back
              Swal.close();
              navigate(-1);
            }
          } catch (error) {
            console.error("Error placing prescription:", error);
            Swal.close();
            navigate(-1);
          }
        } else {
          navigate(-1);
        }
    };
    console.log(location.state);
    if (location.state?.prescription_id) {
    fetchPrescription();
    }

    if(location.state?.cart_id){
      setIsLoaded(true)
    }
  }, [location.state, navigate]);

  const handleGoHome = () => {
    navigate("/dashboard");
  };

  // Render loading or empty state until isLoaded is true
  if (!isLoaded) {
    return null; // or a loading spinner/component
  }

  return (
    <div className="order-placed-success">
            <Header title={"Order Placed"} />

      <div className="flex-container">
        <img
          className="success-image"
          alt="Boy checkout online"
          src="/boy-checkout-online-order-in-sale-period.svg"
        />
        {location.state?.cart_id && (
          <div className="animate__animated animate__bounce">
          <div className="text-wrapper">Order confirmed !</div>
          <p className="p">Your Order ID: # {location.state.cart_id} </p>
          <p className="p">Your order will be delivered soon.</p>

        </div>
              )}

        {location.state?.prescription_id && (
        <div className="animate__animated animate__bounce">
          <div className="text-wrapper">Order placed Successfully !</div>
          <p className="p">Please wait until your order is verified by our executive</p>
        </div>
        )}

      </div>
      <div className="frame-wrapper"onClick={handleGoHome}>
        <div className="div-wrapper">
          <div className="frame-5" >
            <div className="frame-6">
              <div className="text-wrapper-3">Go to Home</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
