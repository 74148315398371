import React, { useState, useEffect } from "react";
import "./style.css";
import Navigation from "../Dashboard/Navigation";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getOffers } from "../../api/Api"; // Import the getOffers function
import HeadActions from "../../components/HeadActions/HeadActions";
import Header from "../Dashboard/Header";
import { DashboardHeader } from "../Dashboard/DashboardHeader";
import { Footer } from "../LandingPage/LandingPage";

export const Offers = () => {
  const navigate = useNavigate();

  const [offers, setOffers] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(true);

  useEffect(() => {
    fetchOffers(page);
  }, [page]);

  const fetchOffers = async (page) => {
    // Show loading spinner
    Swal.fire({
      title: "Loading...",
      text: "Fetching offers, please wait.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const data = await getOffers(page);
      setOffers((prevOffers) => [...prevOffers, ...data.offers]);
      setHasNext(data.has_next);
      Swal.close(); // Close the loading spinner
    } catch (error) {
      Swal.close();
      Swal.fire("Error", "Failed to fetch offers. Please try again.", "error");
    }
  };

  const handleViewOffer = (offer) => {
    navigate("/view-offer", { state: { offer } });
  };

  const handleViewMore = () => {
    if (hasNext) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <>
      <Header title={"Offers"} />

      <div className="offers">
        <div className="overlap-wrapper">
          <div className="overlap-2">
            <div className="overlap-group-2">
              <div className="text-wrapper-8">Offers</div>
            </div>
            <Link to="/rewards" className="text-wrapper-9">
              Rewards
            </Link>
          </div>
        </div>
        <div className="offers-container">
          {offers.map((offer) => (
            <div key={offer.id} className="offers-item">
              <div className="frame">
                <div className="frame-wrapper">
                  <div className="div">
                    <img
                      className="rectangle"
                      alt="Offer"
                      src={
                        "https://medingen.s3.us-east-1.amazonaws.com/banner/" +
                        offer.image
                      }
                    />
                    <div className="div">
                      <div className="frame-2">
                        <p className="p-margin text-wrapper">{offer.title}</p>
                        <p
                          className="p-margin p"
                          dangerouslySetInnerHTML={{
                            __html: offer.description.split("</p>")[0] + "</p>",
                          }}
                        ></p>
                      </div>
                      <div className="div-wrapper">
                        <div className="frame-3">
                          <div
                            onClick={() => handleViewOffer(offer)}
                            className="text-wrapper-2"
                          >
                            {offer.linkText}
                          </div>
                          <img
                            className="vector"
                            alt="Vector"
                            src={offer.linkIcon || "vector-3.svg"} // Use default if linkIcon is missing
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="offers-item">
            {hasNext ? (
              <button onClick={handleViewMore} className="view-more-button">
                View More
              </button>
            ) : (
              <p className="p-margin no-more-offers-text">
                No more offers available at the moment.
              </p>
            )}
          </div>
        </div>
        
        <div className="landing-page">
          <Footer />
        </div>

      </div>
      <Navigation />
    </>
  );
};

export default Offers;
